import React, { useState } from 'react';
import styles from './Header.module.scss';
import { Popover } from 'react-tiny-popover';
import PopoverContent from './PopoverContent';
import { ReactComponent as IconMore } from './svgs/more.svg';

function WeiboHeader() {
  const [pop, setPop] = useState(false);
  const onClickItem = (i: number) => {
    setPop(false);

    if (i === 0) {
      window.location.href = "https://s.weibo.com/top/summary?cate=realtimehot";
    }
    if (i === 1) {
      // ...
    }
  }

  return (
    <div className={styles.header}>
      <div></div>
      <h1>微博热搜</h1>
      {/*@ts-ignore*/}
      <Popover
        isOpen={pop}
        positions={['bottom']}
        onClickOutside={() => setPop(false)}
        content={<PopoverContent onClickItem={onClickItem}></PopoverContent>}
      >
        <IconMore className={styles.more} onClick={() => setPop(!pop)}></IconMore>
      </Popover>
    </div>
  );
}

export default WeiboHeader;
