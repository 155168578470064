import React from 'react';
import styles from './Header.module.scss';

function PopoverContent(props: any) {
  return (
    <div className={styles.pop}>
      <ul>
        <li onClick={() => props.onClickItem(0)}>官方版</li>
        <li onClick={() => props.onClickItem(1)}>关于</li>
      </ul>
    </div>
  );
}

export default PopoverContent;
