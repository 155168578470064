import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getWeiboList, getWeiboTime } from 'api/weibo.api';
import { Article } from 'models/weibo.model';
import { throttle } from 'utils/utils';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './scss/List.module.scss';

function WeiboList() {
  const [list, setList] = useState<Article[]>([]);
  const [time, setTime] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const scrollHandler = throttle(() => {
    Cookies.set('wbhot-scroll-y', String(window.scrollY));
  }, 300);
  const initScrollTop = () => {
    // method 1
    // let val = Cookies.get('wbhot-scroll-y') ?? 0;
    // val = Number(val);
    // window.scrollTo(0, val);

    // method 2
    const query = new URLSearchParams(location.search);
    const i = query.get('i') || 0;
    console.info('wbhot init: ', i);
    if (Number(i) > 10) {
      document.querySelector(`.App > .list > div:nth-child(${i})`)?.scrollIntoView();
    }
  };

  const fetchWeibo = async () => {
    document.removeEventListener('scroll', scrollHandler);
    const res = await getWeiboList();
    setList(res.data.articles);
    document.addEventListener('scroll', scrollHandler);
  };

  const fetchTime = async () => {
    const res = await getWeiboTime();
    const crawlAt = res.data.crawlAt;
    const t = dayjs(crawlAt).format('YYYY-MM-DD HH:mm:ss');
    setTime(t);
  };

  const handClick = (article: Article, i: number) => {
    navigate(
      {
        pathname: location.pathname,
        search: new URLSearchParams({ i: String(i) }).toString(),
      },
      { replace: true }
    );
    window.open(
      `https://s.weibo.com/weibo?q=${encodeURIComponent(article.title)}&Refer=top`,
      '',
      'noopener,noreferrer'
    );
  };

  useEffect(() => {
    fetchWeibo().catch((err) => {
      console.error(err);
    });
    fetchTime().catch((err) => {
      console.error(err);
    });

    initScrollTop();
  }, [location.pathname, list.length]);

  return (
    <>
      <div className={styles['last-time']}>上次更新于：{time}</div>
      <div className={`${styles.list} list`}>
        {list.map((article, i) => {
          return (
            <div
              key={article._id}
              className={styles.linkWarp}
              data-href={`https://s.weibo.com/weibo?q=${encodeURIComponent(
                article.title
              )}&Refer=top`}
              onClick={() => handClick(article, i)}
            >
              <div className={styles.item}>
                <div className={styles['item-row-1']}>
                  <div className={`${styles.index} index-${i + 1}`}>{i + 1}</div>
                  <div className={styles.title}>{article.title}</div>
                </div>
                <div className={styles['item-row-2']}>
                  {article.category ? (
                    <div className={styles.category}>{article.category}</div>
                  ) : null}
                  <div className={styles.hot}>{article.hotNumber}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default WeiboList;
