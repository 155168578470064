export function throttle(func: Function, delay: number) {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  return function (this: any, ...args: any[]) {
    const context = this;
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = undefined;
        func.apply(context, args);
      }, delay);
    }
  };
}
