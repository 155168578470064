import axios from "axios";
import {Article} from "models/weibo.model";

export interface WeiboListResponse {
  data: {
    articles: Article[]
  };
}

export const getWeiboList = (date?: string): Promise<WeiboListResponse> => {
  return axios({
    // url: "http://localhost:5001/weibo",
    url: "//api.hinpc.com/weibo",
    method: "get",
    data: {date}
  }).then(res => {
    return res.data;
  });
}

export const getWeiboTime = (): Promise<any> => {
  return axios({
    // url: "http://localhost:5001/weibo",
    url: "//api.hinpc.com/weibo/system",
    method: "get",
  }).then(res => {
    return res.data;
  });
}
