import React from 'react';
import ScrollToTop from 'react-scroll-up';
import Header from 'components/Header';
import List from './List';
import { ReactComponent as IconTop } from './svgs/top-fill.svg';

function WeiboPage() {
  return (
    <div className="App">
      <Header></Header>

      <List></List>

      {/*@ts-ignore*/}
      <ScrollToTop showUnder={260}>
        <IconTop className="icon-top"></IconTop>
      </ScrollToTop>
    </div>
  );
}

export default WeiboPage;
